<template>
  <Sidebar />
  <div class="min-h-screen flex flex-col">
    <h1 v-if="showHeader" class="text-dark-blue text-2xl sm:text-3xl font-bold text-center mt-8 mb-5">Register My School</h1>
    <div class="max-w-7xl mx-auto px-4 space-y-10 divide-y divide-gray-900/10 pb-10 font-roboto flex-1 w-full">
      <!-- Personal Information -->
      <div v-if="!showPopup" class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-xl sm:text-2xl text-center sm:text-left font-semibold leading-7 text-dark-blue">Personal Information</h2>
        </div>
        <form @submit.prevent="schoolFields.errors || hasFormErrors" class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
              <InputField
                  class="sm:col-span-3"
                  label="First Name*"
                  placeholder="First Name"
                  :error-message="showFormErrors ? schoolFields.errors.value['user.firstName'] : undefined"
                  v-model="schoolForm.user.firstName"
                  required
              />
              <InputField
                  class="sm:col-span-3"
                  label="Last Name*"
                  placeholder="Last Name"
                  :error-message="showFormErrors ? schoolFields.errors.value['user.lastName'] : undefined"
                  v-model="schoolForm.user.lastName"
                  required
              />
              <InputField
                  class="sm:col-span-full"
                  label="Email*"
                  placeholder="Email"
                  :error-message="showFormErrors ? schoolFields.errors.value['user.email'] : undefined"
                  v-model="schoolForm.user.email"
                  required
                  :disabled="keycloak.authenticated"
                  :readonly="keycloak.authenticated"
              />
              <InputField
                  class="sm:col-span-3"
                  label="Phone Number*"
                  placeholder="Phone Number"
                  :error-message="showFormErrors ? schoolFields.errors.value['user.userContacts[0].contact.value'] : undefined"
                  v-model="schoolForm.user.userContacts[0].contact.value"
                  required
              />
              <Dropdown
                  class="sm:col-span-3"
                  :model-value="schoolForm.requestorRoleEnum"
                  :error-message="showFormErrors ? schoolFields.errors.value['requestorRoleEnum'] : undefined"
                  @update:model-value="schoolForm.requestorRoleEnum = $event"
                  placeholder="Select Option"
                  label="Role*"
                  :options="roleOptions"
              />
            </div>
          </div>
        </form>
      </div>

      <div v-if="!showPopup" class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-xl sm:text-2xl text-center sm:text-left font-semibold leading-7 text-dark-blue">School Information</h2>
        </div>
        <form @submit.prevent="schoolFields.errors || hasFormErrors" class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
              <div class="sm:col-span-3">
                <label class="text-sm sm:col-span-3 block font-medium leading-6 text-dark-blue">School Name*</label>
                <SchoolSearch
                    :style="'mt-0 block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none'"
                    :school="schoolForm.school"
                    :filtered-schools="filteredSchools"
                    :placeholder="'School Name'"
                    @school-change="(selectedSchool) => selectSchool(selectedSchool)"
                    @input="filterSchools"
                    :show-register-message="false"
                />
                <span v-if="showFormErrors && schoolFields.errors.value['school.name']" class="text-red-500 text-sm mt-1">{{ schoolFields.errors.value['school.name'] }}</span>
              </div>
              <InputField
                  class="sm:col-span-3"
                  label="School Emis Number"
                  placeholder="Emis Number"
                  v-model="schoolForm.school.emis"
              />
              <InputField
                  class="sm:col-span-full"
                  label="Street Address"
                  placeholder="Street"
                  v-model="schoolForm.school.address.street"
              />
              <InputField
                  class="sm:col-span-2"
                  label="Town*"
                  placeholder="Town"
                  :error-message="showFormErrors ? schoolFields.errors.value['school.address.city'] : undefined"
                  v-model="schoolForm.school.address.city"
                  required
              />
              <Dropdown
                class="sm:col-span-2"
                :model-value="schoolForm.school.address.province"
                :error-message="showFormErrors ? schoolFields.errors.value['school.address.province'] : undefined"
                @update:model-value="schoolForm.school.address.province = $event"
                placeholder="Select Option"
                label="Province*"
                :options="provinceOptions"
              />
              <InputField
                class="sm:col-span-2"
                label="Postal Code"
                placeholder="Postal Code"
                v-model="schoolForm.school.address.postalCode"
              />
              <Dropdown
                  class="sm:col-span-3"
                  :model-value="schoolForm.school.schoolType"
                  :error-message="showFormErrors ? schoolFields.errors.value['school.schoolType'] : undefined"
                  @update:model-value="schoolForm.school.schoolType = $event"
                  placeholder="Select Option"
                  label="Public or Private School*"
                  :options="schoolTypeOptions"
              />
              
              <fieldset class="col-span-full">
                <div class="space-y-3">
                  <Checkbox
                      :model-value="schoolForm.termsAccepted"
                      @update:model-value="schoolForm.termsAccepted = !schoolForm.termsAccepted"
                      style-class="relative flex gap-x-3"
                      :error-message="showFormErrors ? schoolFields.errors.value['termsAccepted'] : undefined"
                      required>

                    <template v-slot:statement>
                      <label class="font-medium text-dark-blue">
                        <p href="#">
                          I accept the GetGo
                          <a class="text-light-blue underline" href="/docs/Privacy_Policy.pdf">Privacy Policy</a>
                          and
                          <a class="text-light-blue underline" href="/docs/T&C.pdf">Terms and Conditions</a>
                        </p>
                      </label>
                    </template>

                  </Checkbox>
                </div>
              </fieldset>

              <div class="col-span-full sm:w-96 sm:mx-auto mt-0 sm:mt-4">
                <Button 
                  type="submit"
                  @click.prevent="handleSubmit"
                  buttonText="Register"
                  class="bg-light-blue text-white py-2.5 w-full rounded-md"
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Popup -->
      <div v-if="showPopup">
        <div class="bg-white p-6 mt-3 md:mx-auto">
          <div class="text-center">
            <h3 class="text-2xl text-dark-blue font-semibold text-center">{{ popupTitle }}</h3>
            <p class="text-dark-blue text-center mt-2.5 text-base">{{ popupShortMessage }}</p>
            <div v-if="showPopupImage" class="flex justify-center mt-3.5">
              <img src="/img/campaign_created_success.svg" alt="Success Image">
            </div>
            <p v-html="popupLongMessage" class="mt-6 mb-8 w-72 sm:w-96 mx-auto text-sm text-dark-blue whitespace-normal">
            </p>
            <div class="text-center">
              <a href="/landing" class="px-12 bg-light-blue hover:opacity-90 rounded-md text-white font-semibold py-3 uppercase">
                Continue 
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div> 
</template>

<script setup lang="ts">
import Sidebar from '@/components/Layouts/Sidebar.vue'
import Footer from '@/components/Atomic/AQuarks/Footer.vue'
import Button from '@/components/Atomic/AQuarks/Button.vue'
import * as yup from 'yup';
import {useSchoolStore} from '@/areas/schools/stores/schoolStore'
import {SchoolStateEnum} from '@/areas/schools/enums/schoolStateEnum'
import {onBeforeUnmount, onMounted, PropType, reactive, ref, watch} from 'vue'
import {ContactTypeEnum} from "@/areas/schools/enums/contactTypeEnum";
import {ProvincesEnum} from '@/areas/schools/enums/provincesEnum'
import {RequestorRoleEnum} from '@/areas/schools/enums/requestorRoleEnum'
import keycloak from '@/keycloak'
import {SchoolDto} from "@/areas/schools/dtos/schoolDto";
import {SchoolTypeEnum} from "@/areas/schools/enums/schoolTypeEnum";
import {SchoolRequestDto} from "@/areas/schools/dtos/schoolRequestDto";
import {useForm} from "vee-validate";
import InputField from "@/components/Atomic/AQuarks/InputField.vue";
import Dropdown from "@/components/Atomic/AQuarks/Dropdown.vue";
import SchoolSearch from "@/components/Atomic/BAtoms/SchoolSearch.vue";
import Checkbox from "@/components/Atomic/AQuarks/Checkbox.vue";
import {emailRegex, phoneRegex} from "@/helpers/utilities/regex";
import {BankAccountTypeEnum} from "@/areas/schools/enums/bankAccountTypeEnum";
import {useUserStore} from "@/areas/users/stores/userStore";

const props = defineProps({
  schoolRequestDto: {
    type: Object as PropType<SchoolRequestDto>,
    default: {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        userContacts: [{
          contact: {
            value: '',
            contactTypeEnum: ContactTypeEnum.Mobile
          },
        }],
      },
      school: {
        name: '',
        emis: '',
        address: {
          street: '',
          city: '',
          province: undefined,
          postalCode: '',
        },
        bankDetail: {
          name: '',
          branchCode: '',
          bankAccountType: BankAccountTypeEnum.Cheque,
          accountNumber: '',
        },
        schoolType: SchoolTypeEnum.Public,
      },
      requestorRoleEnum: undefined,
      termsAccepted: false
    }
  }
})

const schoolStore = useSchoolStore()
const userStore = useUserStore()
const schools = ref<SchoolDto[]>([])
const filteredSchools = ref<SchoolDto[]>([])

// Enums
const provinceOptions = Object.values(ProvincesEnum)
const roleOptions = Object.values(RequestorRoleEnum)
const schoolStates = Object.values(SchoolStateEnum)
const schoolTypeOptions = Object.values(SchoolTypeEnum)

// Display flags
const showHeader = ref<boolean>(true)
const showFormErrors = ref<boolean>(false)
const showProvinceDropdown = ref<boolean>(false)
const showRoleDropdown = ref<boolean>(false)
const showSchoolTypeDropdown = ref<boolean>(false)
const showPopup = ref<boolean>(false)
const showPopupImage = ref<boolean>(false)

// Error messages
const popupLongMessage = ref<string>()
const popupShortMessage = ref<string>()
const popupTitle = ref<string>()
const hasFormErrors = ref<boolean>(true)

let searchTerm = '';
const resultSize = ref<number>(5)

const schoolForm = reactive(props.schoolRequestDto)

const validationSchema = yup.object({
  user: yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().required('Email is required').email('Must be a valid email').matches(emailRegex, {message: 'Invalid email address'}),
    userContacts: yup.array().of(yup.object({
        contact: yup.object({
          value: yup.string().required('Mobile Number is required').matches(phoneRegex, {message: 'Contact Number must be a valid South African phone number'}).matches(phoneRegex, 'Valid South African phone number is required'),
        }).required("Contact Information is required")
    }))
  }),
  requestorRoleEnum: yup.mixed().oneOf(roleOptions).required("Role is required"),
  school: yup.object({
    name: yup.string().required("School Name is required"),
    emis: yup.string(),
    address: yup.object({
      street: yup.string().notRequired(),
      city: yup.string().required("School Town is required"),
      province: yup.mixed().required("Province is required"),
      postalCode: yup.string().notRequired(),
    }),
    schoolType: yup.mixed().oneOf(schoolTypeOptions).required("School Type is required")
  }),
  termsAccepted: yup.boolean().isTrue("Read and Accept the Terms and Conditions")
})

const schoolFields = useForm({
  validationSchema: validationSchema,
  initialValues: schoolForm
})

watch(() => schoolForm, async (newValue) => {
  schoolFields.resetForm({
    values: newValue,
    errors: {},
    touched: {}
  })
  
  const validationResult = await schoolFields.validate()
  hasFormErrors.value = !validationResult.valid
}, {deep:true})

const fetchAllSchools = async () => {
  let result = await schoolStore.fetchAllSchools(schoolStates, searchTerm);
  
  if (result.isSuccessful){
    schools.value = result.content! as SchoolDto[]
  }
};

onMounted(async () => {
  await fetchAllSchools();
  
  if (keycloak.authenticated){
    let keycloakUser = await keycloak.loadUserProfile()
    schoolForm.user!.firstName = keycloakUser.firstName!
    schoolForm.user!.lastName = keycloakUser.lastName!
    schoolForm.user!.email = keycloakUser.email!
    
    let userResult = await userStore.getUser(keycloakUser.id!)
    
    if (userResult.isSuccessful){
      schoolForm.user!.userContacts = userResult.content!.userContacts
    }
  }
  window.addEventListener('click', closeAllDropdowns);
})

onBeforeUnmount(() => {
  window.removeEventListener('click', closeAllDropdowns);
})

const closeAllDropdowns = () => {
  showRoleDropdown.value = false;
  showSchoolTypeDropdown.value = false;
  showProvinceDropdown.value = false;
}
const setShowPopup = (newShowPopup: boolean, newShowPopupImage: boolean) => {
  showPopup.value = newShowPopup;
  showPopupImage.value = newShowPopupImage;
}

const handleSubmit = async () => {
  if (!showFormErrors.value) {
    showFormErrors.value = true
  }

  if (hasFormErrors.value) {
    return
  }
  
  const schoolRequest = { 
    school: schoolForm!.school,
    user: schoolForm!.user,
    requestorRoleEnum: schoolForm!.requestorRoleEnum
  } as SchoolRequestDto
  
  const response = await schoolStore.schoolInfoRegistration(schoolRequest);
  
  if (response.isSuccessful) {
    setShowPopup(true, true)
    popupTitle.value = 'Registration Received';
    popupShortMessage.value = 'Thank you for registering';
    popupLongMessage.value = `
            We are currently processing your registration. 
            Please check your email for the next steps. 
            For further support please contact <a class="text-light-blue underline" href="mailto:support@getgofund.org">support@getgofund.org</a>.
          `
    window.scrollTo(0, 0);
  } else {
    const error = response.error;
    setShowPopup(true, false)
    showHeader.value = false
    popupTitle.value = 'Registration Failed';

    switch (error) {
      case 'SchoolAlreadyRequestedByUser':
        popupShortMessage.value = 'School already exists';
        popupLongMessage.value = `For further support please contact <a class="text-light-blue underline" href="mailto:support@getgofund.org">support@getgofund.org</a>.`;
        break;

      case 'SchoolDuplicateAlreadyActivated':
        popupShortMessage.value = "The school already exists and is approved for use.";
        popupLongMessage.value = `For further support please contact <a class="text-light-blue underline" href="mailto:support@getgofund.org">support@getgofund.org</a>.`;
        break;

      case 'SchoolInRejectedState':
        popupShortMessage.value = "The school already exists, but has been rejected.";
        popupLongMessage.value = `For further support please contact <a class="text-light-blue underline" href="mailto:support@getgofund.org">support@getgofund.org</a>.`;
        break;

      default:
        popupShortMessage.value = 'There was an error during registration.';
        break;
    }
    window.scrollTo(0, 0);
  }
}

const filterSchools = async (newInput: string) => {
  searchTerm = newInput
  
  schoolForm!.school!.name = newInput
  schoolForm!.school!.guid = undefined
  
  schoolForm!.school!.schoolRequests = undefined
  schoolForm!.school!.contactPersonDetails = undefined
  schoolForm!.school!.contactPersonName = undefined
  schoolForm!.school!.bankDetail = {
    accountNumber: '',
    name: '',
    branchCode: '',
    bankAccountType: BankAccountTypeEnum.Cheque
  }
  schoolForm!.school.notes = ''
  schoolForm!.school.entityState = SchoolStateEnum.Loaded

  // Reset school select
  if (schoolForm.school.guid){
    schoolForm!.school = {
      name: newInput,
    } as SchoolDto
  }

  if (!schools.value){
    return
  }
  filteredSchools.value = schools.value!.filter(school => school.name?.toLowerCase().includes(searchTerm.toLowerCase())).splice(0, resultSize.value)
}

// Method to select a school from dropdown
const selectSchool = (school: SchoolDto) => {
  Object.assign(schoolForm?.school, school)
  
  filteredSchools.value = [];
}
</script>
