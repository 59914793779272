<template>
  <div class="relative w-full">
    <input
        @focus="showResults"
        @blur="hideSearchResults"
        type="text"
        :placeholder="placeholder"
        :class="style"
        class="mt-2 border border-gray-300 rounded-md py-1.5 px-2 outline-none shadow-sm w-80 sm:w-full"
        @input="emit('input', searchTerm)"
        v-model="searchTerm"
    /> 
    <div v-show="showSearchResults" :class="'absolute z-50 w-full'">
      <div class="mt-1 rounded-md bg-white shadow-lg" style="top: calc(100% + 5px);">
        <ul v-show="filteredSchools.length > 0" v-for="school in filteredSchools" :key="school.guid" class="text-dark-blue px-3 py-2 hover:bg-gray-100 cursor-pointer text-left" @click="selectSchool(school)">{{ school.name }}, {{ school.address.city }}, {{ school.address.province }}</ul>
        <ul v-show="showRegisterMessage && searchTerm.length > 0 && filteredSchools.length === 0" @click="handleRegisterSchool" class="text-dark-blue px-3 py-2 hover:bg-gray-100 cursor-pointer text-left">
          {{ 'Your School is not registered with GetGo yet, click to Register New School' }}
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {PropType, ref, watch} from "vue";
import {SchoolDto} from "@/areas/schools/dtos/schoolDto";
import router from "@/router";

const props = defineProps({
  // List of objects to search
  school: {
    type: Object as PropType<SchoolDto | undefined>,
    default: undefined
  },
  // Custom label styling
  style: {
    type: String,
  },
  filteredSchools: {
    type: Array as PropType<SchoolDto[]>,
    default: [],
  },
  showSearchResults: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: "Search..."
  },
  showRegisterMessage: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['school-change', 'input'])

const searchTerm = ref(props.school?.name || '')
const filteredSchools = ref<SchoolDto[]>(props.filteredSchools || [])
const showSearchResults = ref(props.showSearchResults)
const placeholder = ref(props.placeholder)

watch(() => props.school, (newSchool) => {
  searchTerm.value = newSchool?.name || ''
}, {
  immediate: true
});

watch(() => props.filteredSchools, (newValue) => {
  filteredSchools.value = props.filteredSchools
}, {
  immediate: true
});

const showResults = () => {
  showSearchResults.value = true
}
const hideSearchResults = () => {
  setTimeout(() => {
    showSearchResults.value = false;
  }, 200); // adjust delay as necessary
}

const handleRegisterSchool = async () => {
  router.push({
    name: 'Register My School',
  })
}

const selectSchool = (school: SchoolDto) => {
  searchTerm.value = school.name
  filteredSchools.value = []
  
  emit('school-change', school)
}
</script>
